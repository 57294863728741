<template>
  <div class="view-box">

    <div class="flex">
      <div class="view-right">
        <div class="image-box">
          <el-image style="width: 400px; height: 300px" :src="url" :preview-src-list="srcList"></el-image>
        </div>
        <div class="dot-box">
          <i class="el-icon-d-arrow-left" @click="clickDot(0)"></i>
          <el-image :class="{active:currentIndex==index}" class="dot-img" :src="item" v-for="item,index in srcList" :key="index" @click="clickDotImg(item,index)"></el-image>
          <i class="el-icon-d-arrow-right" @click="clickDot(1)"></i>
        </div>
      </div>
      <div class="view-left">
        <div class="left-header">
          <div class="header-name">
            <div class="name-text">这里是小区名称xxxxxxxxxxxx</div>
            <el-tag type="info" effect="dark" size="small" class="mr20">住宅</el-tag>
            <div class="f12">编号：ES000001</div>
          </div>
          <el-dropdown placement="bottom" @command="command">
            <el-button type="primary" size="medium">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="dialogVisibleUp">写跟进</el-dropdown-item>
              <el-dropdown-item command="dialogVisibleView">写带看</el-dropdown-item>
              <el-dropdown-item command="dialogVisibleConvert">公转私</el-dropdown-item>
              <el-dropdown-item command="dialogVisibleKey">钥匙管理</el-dropdown-item>
              <el-dropdown-item command="dialogVisibleBook">委托书</el-dropdown-item>
              <el-dropdown-item command="dialogVisiblePrice">调价格</el-dropdown-item>
              <el-dropdown-item command="dialogVisiblePeople">变更负责人</el-dropdown-item>
              <el-dropdown-item command="edit">编辑房源</el-dropdown-item>
              <el-dropdown-item command="dialogVisibleVideo">编辑图片视频</el-dropdown-item>
              <el-dropdown-item command="dialogVisibleInvalid">转无效</el-dropdown-item>
              <el-dropdown-item command="delete">删除房源</el-dropdown-item>
              <el-dropdown-item command="dialogVisibleAll">全部记录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="price-row">
          <div class="mr20">
            <span class="f24">1360</span>
            <span class="fe615a">元/月</span>
          </div>
          <el-tag effect="plain" size="small">整租</el-tag>

        </div>
        <div class="tag-box">
          <el-tag effect="plain" class="mr10" type="success" size="small">床</el-tag>
          <el-tag effect="plain" class="mr10" type="success" size="small">冰箱</el-tag>
          <el-tag effect="plain" class="mr10" type="success" size="small">电视</el-tag>
          <el-tag effect="plain" class="mr10" type="success" size="small">空调</el-tag>
        </div>

        <div class="house-desc ">
          <div>
            <div class="mb20 f20">3室2厅2卫</div>
            <div class="c666">5层/共8层</div>
          </div>
          <div>
            <div class="mb20 f20">127m²</div>
            <div class="c666">简装</div>
          </div>
          <div>
            <div class="mb20 f20">南</div>
            <div class="c666">2梯6户</div>
          </div>
        </div>

        <el-row>
          <el-col :span="10">
            <div class="flex">
              <div class="people-box">
                <i class="el-icon-user"></i>
              </div>
              <div>
                <div class="shop-name">广东A店</div>
                <div class="mb20">陈星</div>
                <div>负责人</div>
              </div>
            </div>
          </el-col>
          <el-col :span="10">
            <div class="flex">
              <div class="people-box">
                <i class="el-icon-user"></i>
              </div>
              <div>
                <div class="shop-name">广东A店</div>
                <div class="mb20">陈星</div>
                <div>录入人</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="info-title">基本参数</div>
    <div class="c666">
      <div class="flex-wrap ">
        <div>
          <span class="title-left">房源等级：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
        <div>
          <span class="title-left">盘别：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
        <div>
          <span class="title-left">类型：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
      </div>
      <div class="flex-wrap ">
        <div>
          <span class="title-left">付款方式：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
        <div>
          <span class="title-left">最短租期：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
        <div>
          <span class="title-left">到期时间：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
      </div>
      <div class="flex-wrap ">
        <div>
          <span class="title-left">物业费：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
        <div>
          <span class="title-left">费用包含：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
      </div>
      <div class="flex-wrap ">
        <div>
          <span class="title-left">配套：</span>
          <span class="title-desc" style="width:auto">
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="复选框 A" disabled>床</el-checkbox>
              <el-checkbox label="复选框 B" disabled>电视</el-checkbox>
              <el-checkbox label="复选框 C" disabled>空调</el-checkbox>
              <el-checkbox label="禁用1" disabled>冰箱</el-checkbox>
              <el-checkbox label="选中且禁用2" disabled>洗衣机</el-checkbox>
              <el-checkbox label="禁用2" disabled>沙发</el-checkbox>
              <el-checkbox label="选中且禁用3" disabled>餐桌</el-checkbox>
            </el-checkbox-group>
          </span>
        </div>
      </div>
      <div class="flex-wrap ">
        <div>
          <span class="title-left">备注：</span>
          <span class="title-desc" style="width:auto">xxxxxxxxxxxxxxxx</span>
        </div>
      </div>
    </div>

    <div class="info-title color409eff" @click="show=!show">
      其他
      <i class="el-icon-arrow-down" :class="show?'arrowRotate':'arrow'"></i>
    </div>
    <el-collapse-transition>
      <div class="c666" v-if="show">
        <div class="flex-wrap ">
          <div>
            <span class="title-left">业主姓名：</span>
            <span class="title-desc">xxxxxxxxxxxxxxxx</span>
          </div>
          <div>
            <span class="title-left">业主电话：</span>
            <span class="title-desc">xxxxxxxxxxxxxxxx</span>
          </div>
          <div>
            <span class="title-left">来源渠道：</span>
            <span class="title-desc">xxxxxxxxxxxxxxxx</span>
          </div>
        </div>
        <div class="flex-wrap ">
          <div>
            <span class="title-left">付款方式：</span>
            <span class="title-desc">xxxxxxxxxxxxxxxx</span>
          </div>
          <div>
            <span class="title-left">现状：</span>
            <span class="title-desc">xxxxxxxxxxxxxxxx</span>
          </div>
          <div>
            <span class="title-left">小区：</span>
            <span class="title-desc">xxxxxxxxxxxxxxxx</span>
          </div>
        </div>
        <div class="flex-wrap ">
          <div>
            <span class="title-left">栋座：</span>
            <span class="title-desc">xxxxxxxxxxxxxxxx</span>
          </div>
          <div>
            <span class="title-left">单元：</span>
            <span class="title-desc">xxxxxxxxxxxxxxxx</span>
          </div>
          <div>
            <span class="title-left">房号：</span>
            <span class="title-desc">xxxxxxxxxxxxxxxx</span>
          </div>
        </div>
        <div class="flex-wrap ">
          <div>
            <span class="title-left">隐私备注：</span>
            <span class="title-desc" style="width:auto">xxxxxxxxxxxxxxxx</span>
          </div>
        </div>
      </div>
    </el-collapse-transition>
    <!-- 写跟进 -->
    <el-dialog title="写跟进" :visible.sync="dialogVisibleUp" width="50%">
      <el-form label-width="120px">
        <el-form-item label="跟进内容：">
          <el-input type="textarea" :rows="8" placeholder="请输入内容" maxlength="300" show-word-limit>
          </el-input>
        </el-form-item>
        <el-form-item label="图片上传：">
          <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>最多5张图片，单张大小不超过10M</div>
          <el-dialog :visible.sync="Visible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleUp = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisibleUp = false" size="medium">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 写带看 -->
    <el-dialog title="写带看" :visible.sync="dialogVisibleView" width="50%">
      <el-form label-width="120px" :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item label="带看客户：" prop="name">
          <el-input placeholder="请输入内容" prefix-icon="el-icon-search" style="width:80%"></el-input>
        </el-form-item>
        <el-form-item label="带看人：" prop="name">
          <el-select placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="带看日期：" prop="name">
          <div style="display:flex;align-items:center">
            <el-date-picker type="date" placeholder="选择日期" style="margin-right: 10px;"></el-date-picker>
            <el-radio-group size="medium">
              <el-radio-button label="上午">上午</el-radio-button>
              <el-radio-button label="下午">下午</el-radio-button>
              <el-radio-button label="晚上">晚上</el-radio-button>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="反馈：">
          <el-input type="textarea" :rows="8" placeholder="请输入内容" maxlength="300" show-word-limit>
          </el-input>
        </el-form-item>
        <el-form-item label="图片：">
          <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>最多5张图片，单张大小不超过10M</div>
          <el-dialog :visible.sync="Visible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleView = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisibleView = false" size="medium">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 图片视频管理 -->
    <el-dialog title="图片视频管理" :visible.sync="dialogVisibleVideo" width="50%">
      <el-form label-width="120px">
        <el-form-item label="视频：">
          <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>每个房源免费支持上传1个视频，限定大小不超过10M</div>
        </el-form-item>
        <el-form-item label="图片：">
          <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>最多5张图片，单张大小不超过10M</div>
          <el-dialog :visible.sync="Visible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleVideo = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisibleVideo = false" size="medium">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 变更负责人 -->
    <el-dialog title="变更负责人" :visible.sync="dialogVisiblePeople" width="40%">
      <el-form label-width="120px">
        <el-form-item label="当前负责人：">
          <span>xxxxxxxx</span>
        </el-form-item>
        <el-form-item label="更改为：">
          <el-select placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisiblePeople = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisiblePeople = false" size="medium">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 全部记录 -->
    <el-dialog title="全部记录" :visible.sync="dialogVisibleAll" width="70%">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="全部" name="first"></el-tab-pane>
        <el-tab-pane label="跟进" name="second"></el-tab-pane>
        <el-tab-pane label="带看" name="third"></el-tab-pane>
        <el-tab-pane label="查看" name="fourth"></el-tab-pane>
        <el-tab-pane label="其他" name="fourth1"></el-tab-pane>
      </el-tabs>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="date" label="操作时间  " width="180">
        </el-table-column>
        <el-table-column prop="name" label="操作人员" width="180">
        </el-table-column>
        <el-table-column prop="address" label="操作记录">
        </el-table-column>
        <el-table-column prop="address" label="图片" width="80">
          <template>
            <el-image style="width: 50px; height: 50px" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleAll = false" size="medium">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 转无效 -->
    <el-dialog title="转无效" :visible.sync="dialogVisibleInvalid" width="40%">
      <el-form label-width="120px">
        <el-form-item label="选择原因：">
          <el-select placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleInvalid = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisibleInvalid = false" size="medium">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 调价格 -->
    <el-dialog title="调价格" :visible.sync="dialogVisiblePrice" width="40%">
      <el-form label-width="80px">
        <el-form-item label="价格：">
          <el-input placeholder="请输入">
            <template slot="append">元/月</template>
          </el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input type="textarea" :rows="8" placeholder="请输入内容" maxlength="300" show-word-limit>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisiblePrice = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisiblePrice = false" size="medium">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 公私转 -->
    <el-dialog title="公转私" :visible.sync="dialogVisibleConvert" width="30%">
      <div>
        <div style="margin-bottom: 10px;">确定转为私盘？</div>
        <div>（转为私盘后，只有负责人和他上级可以查看业主电话）</div>
      </div>
      <!-- <div>
        <div style="margin-bottom: 10px;">确定转为公盘？</div>
        <div>（转为公盘后，公司里所有人都可以直接看到业主电话）</div>
      </div> -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleConvert = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisibleConvert = false" size="medium">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 委托书 -->
    <el-dialog :visible.sync="dialogVisibleBook" title="委托书">
      <div v-if="fileList.length===0" class="book-box">
        <div class="book-title">尚未上传委托书</div>
        <el-upload class="upload-demo" action="/api/upload" :on-change="handleChange" :file-list="fileList" :show-file-list="false" :accept="'application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document'">
          <el-button type="primary" plain>上传委托书</el-button>
          <div slot="tip" class="el-upload__tip">支持XLSX、PDF、DOCX格式</div>
        </el-upload>
      </div>
      <div v-else>
        <div class="book-text">
          <div class="mr30">委托书:</div>
          <div>
            <div>文件名</div>
            <div class="book-desc">可点击直接下载委托书</div>
            <el-upload class="upload-demo" action="/api/upload" :on-change="handleChange" :file-list="fileList" :show-file-list="false" :accept="'application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document'">
              <el-button type="primary" plain>重新上传</el-button>
            </el-upload>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleBook = false" size="medium">确定上传</el-button>
        <el-button @click="dialogVisibleBook = false" size="medium">关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 上传钥匙 -->
    <el-dialog title="查看钥匙" :visible.sync="dialogVisibleKey">
      <!-- <div class="book-box">
        <div class="book-title">暂无钥匙</div>
        <el-button type="primary" plain @click="innerVisible=true">上传钥匙</el-button>
      </div> -->
      <div class="key-edit">
        <div class="key-item">
          <div style="flex:1" class="right">钥匙编号：</div>
          <div style="flex:2">0510Y001</div>
          <div style="flex:1">
            <span class="edit" @click="innerVisible=true">编辑</span>
            <span class="edit" @click="open">退还</span>
          </div>
        </div>
        <div class="key-item">
          <div style="flex:1" class="right">收钥人：</div>
          <div style="flex:2">陈大星</div>
          <div style="flex:1"></div>
        </div>
        <div class="key-item">
          <div style="flex:1" class="right">收钥日期：</div>
          <div style="flex:2">2023-05-10</div>
          <div style="flex:1"></div>
        </div>
        <div class="key-item">
          <div style="flex:1" class="right">备注：</div>
          <div style="flex:2">xxxxxxxxxxxxxxxxx</div>
          <div style="flex:1"></div>
        </div>
      </div>
      <el-dialog width="50%" title="上传钥匙" :visible.sync="innerVisible" append-to-body>
        <el-form label-width="120px">
          <el-form-item label="收钥人：">
            <div>陈星</div>
          </el-form-item>
          <el-form-item label="收钥日期：">
            <el-date-picker type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input type="textarea" :rows="8" placeholder="请输入内容" maxlength="300" show-word-limit>
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="innerVisible = false" size="medium">确定</el-button>
          <el-button @click="innerVisible = false" size="medium">关 闭</el-button>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleKey = false" size="medium">关 闭</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'EstateProjectViewRentHouseDwell',
  data () {
    return {
      currentIndex: 0,
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: [
        'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      ],
      show: false,
      checkList: ['复选框 A', '复选框 B'],
      // 写跟进
      dialogVisibleUp: false,
      dialogImageUrl: '',
      Visible: false,
      // 写带看
      dialogVisibleView: false,
      ruleForm: {
        name: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      },
      options: [{ value: '选项1', label: '黄金糕' }],
      // 图片视频管理
      dialogVisibleVideo: false,
      // 变更负责人
      dialogVisiblePeople: false,
      // 全部记录
      activeName: 'first',
      dialogVisibleAll: false,
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }
      ],
      // 转无效
      dialogVisibleInvalid: false,
      // 调价格
      dialogVisiblePrice: false,
      // 公私转
      dialogVisibleConvert: false,
      // 委托书
      dialogVisibleBook: false,
      fileList: [],
      // 上传钥匙
      dialogVisibleKey: false,
      innerVisible: false
    }
  },
  methods: {
    clickDotImg (item, index) {
      this.url = item
      this.currentIndex = index
    },
    clickDot (flag) {
      if (flag) {
        if (this.currentIndex === this.srcList.length - 1) {
          this.currentIndex = 0
        } else {
          this.currentIndex++
        }
      } else {
        if (this.currentIndex === 0) {
          this.currentIndex = this.srcList.length - 1
        } else {
          this.currentIndex--
        }
      }
      this.url = this.srcList[this.currentIndex]
    },
    open () {
      this.$confirm('确认退还钥匙？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    command (item) {
      if (item === 'edit') {
        return this.$root.navigate({ name: 'EstateProjectAddRentHouseDwell' })
      }
      if (item === 'delete') {
        return this.$confirm(
          '确定删除楼盘（ XF001001佛山顺德碧桂园一期）?（删除后将不能恢复，请谨慎操作）',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
      }
      this[item] = true
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    }
  },
  mounted () { }
}
</script>

<style lang="scss" scoped>
.view-box {
  border-radius: 6px;
  background-color: #fff;
  padding: 20px;
  font-size: 14px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
}
.mr10 {
  margin-right: 10px;
}
.dot-box {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  i {
    font-size: 36px;
    cursor: pointer;
  }
}
.view-right {
  margin-right: 20px;
}
.view-left {
  flex: 1;
  .left-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-name {
      display: flex;
      align-items: center;
      .name-text {
        font-size: 18px;
        font-weight: 900;
        margin-right: 20px;
      }
      .f12 {
        font-size: 12px;
      }
      .mr20 {
        margin-right: 20px;
      }
    }
  }
}
.fe615a {
  color: #fe615a;
}
.price-row {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .f24 {
    font-size: 36px;
    color: #fe615a;
    font-weight: 900;
    margin: 0 10px;
  }

  .mr20 {
    margin-right: 20px;
  }
  .el-tag {
    padding: 0 25px;
  }
}
.tag-box {
  margin: 15px 0;
}
.mb20 {
  margin-bottom: 20px;
}
.all-btn {
  margin-left: 20px;
  cursor: pointer;
  color: #409eff;
}
.people-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 118px;
  height: 118px;
  background: #409eff;
  font-size: 100px;
  color: #fff;
  border-radius: 8px;
  margin-right: 20px;
}
.shop-name {
  margin: 20px 0 15px;
  font-weight: 900;
}
.info-title {
  font-weight: 900;
  font-size: 18px;
  margin: 20px 0;
}
.c666 {
  color: #666666;
}
.mb30 {
  margin-bottom: 30px;
}
.color409eff {
  color: #409eff;
  cursor: pointer;
}
.house-desc {
  display: flex;
  align-items: center;
  padding: 15px 0;
  margin-right: 50px;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
  margin-bottom: 20px;
  > div {
    width: 25%;
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .f20 {
    font-size: 20px;
    font-weight: 900;
  }
}
.book-box {
  text-align: center;
  .book-title {
    font-size: 28px;
    color: #666;
    margin-bottom: 20px;
  }
}
.book-text {
  padding-left: 80px;
  display: flex;
  color: #666;
  font-size: 18px;
  .mr30 {
    margin-right: 30px;
  }
  .book-desc {
    font-size: 14px;
    margin: 10px 0;
  }
}
.key-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .right {
    text-align: right;
  }
  .edit {
    margin-right: 30px;
    color: #409eff;
    cursor: pointer;
  }
}
.image-box {
  box-sizing: border-box;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 6px;
}
.dot-img {
  cursor: pointer;
  width: 50px;
  height: 50px;
}
.active {
  box-sizing: border-box;
  border: 2px solid red;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  > div {
    display: flex;
    margin-bottom: 30px;
  }
  .title-left {
    flex-shrink: 0;
    width: 100px;
    text-align: right;
  }
  .title-desc {
    width: 300px;
    word-wrap: break-word;
    word-break: break-all;
  }
}
</style>
