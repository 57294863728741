<template>
  <div>
    <div class="title-flex">
      <div class="all-total">共<span>567</span>条数据</div>
      <div class="right-tab">
        <div v-for="item in ['智能排序','按创建时间排序']" :key="item" :class="{active:current==item}" @click="current=item">{{item}}</div>
      </div>
    </div>

    <!-- table表格 -->
    <el-table :data="tableData" style="width: 100%" class="mb20 hide-borders">
      <el-table-column prop="date" label="基本状况" width="420" fixed>
        <template>
          <div class="flex">
            <el-image :src="url" :preview-src-list="srcList" class="img-box"></el-image>
            <div class="tag-content">
              <div class="fw600 f16">保利新小区8栋5单元808</div>
              <div class="btn-zu">
                <el-tag effect="dark" size="medium" class="mr">公盘</el-tag>
                <el-tag effect="plain" size="medium">稳定在售</el-tag>
              </div>
              <div class="tag-box">
                <el-tag effect="plain" size="medium" type="success">预约</el-tag>
              </div>
            </div>
          </div>

        </template>
      </el-table-column>

      <el-table-column prop="address" label="价格">
        <template>
          <div class="color2e4543 mb6">1800元/月</div>
          <div class="colora7abba">押二付一</div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="面积/装修">
        <template>
          <div class="color2e4543 mb6">80~170m²</div>
          <div class="colora7abba">精装 东南</div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="户型/类型">
        <template>
          <div class="color2e4543 mb6">3室2厅2卫</div>
          <div class="colora7abba">普通住宅</div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="楼层/总楼层">
        <template>
          <div class="color2e4543 mb6">9层</div>
          <div class="colora7abba">共24层</div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="负责人/录入时间">
        <template>
          <div class="color2e4543 mb6">陈星</div>
          <div class="colora7abba">2023-06-09 15:15</div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" width="120" fixed="right">
        <template>
          <div>
            <el-button type="text" size="mini" class="f14" @click="$root.navigate({name:'EstateProjectViewRentHouseDwell'})">查看房源</el-button>
          </div>
          <div>
            <el-button type="text" size="mini" class="f14" @click="$root.navigate({name:'EstateProjectViewRentHouseVilla'})">查看房源</el-button>
          </div>
          <div>
            <el-button type="text" size="mini" class="f14" @click="dialogVisibleConvert=true">公转私</el-button>
          </div>
          <div>
            <el-button type="text" size="mini" class="f14" @click="dialogVisibleAll=true">全部记录</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-box ">
      <!-- 分页 -->
      <el-pagination background :current-page="currentPage4" :page-sizes="[20,40,80,100]" :page-size="20" layout="total, prev, pager, next, sizes" :total="100">
      </el-pagination>
    </div>

    <!-- 全部记录 -->
    <el-dialog title="全部记录" :visible.sync="dialogVisibleAll" width="70%">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="全部" name="first"></el-tab-pane>
        <el-tab-pane label="跟进" name="second"></el-tab-pane>
        <el-tab-pane label="带看" name="third"></el-tab-pane>
        <el-tab-pane label="查看" name="fourth"></el-tab-pane>
        <el-tab-pane label="其他" name="fourth1"></el-tab-pane>
      </el-tabs>
      <el-table :data="tableData1" border style="width: 100%">
        <el-table-column prop="date" label="操作时间  " width="180">
        </el-table-column>
        <el-table-column prop="name" label="操作人员" width="180">
        </el-table-column>
        <el-table-column prop="address" label="操作记录">
        </el-table-column>
        <el-table-column prop="address" label="图片" width="80">
          <template>
            <el-image style="width: 50px; height: 50px" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleAll = false" size="medium">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 公私转 -->
    <el-dialog title="公转私" :visible.sync="dialogVisibleConvert" width="30%">
      <div>
        <div style="margin-bottom: 10px;">确定转为私盘？</div>
        <div>（转为私盘后，只有负责人和他上级可以查看业主电话）</div>
      </div>
      <!-- <div>
        <div style="margin-bottom: 10px;">确定转为公盘？</div>
        <div>（转为公盘后，公司里所有人都可以直接看到业主电话）</div>
      </div> -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleConvert = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisibleConvert = false" size="medium">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'EstateProjectRentHouse',
  data () {
    return {
      current: '智能排序',
      tableData: [
        { date: '2016-05-02', name: '王小虎', address: '1' },
        { date: '2016-05-04', name: '王小虎', address: '1' },
        { date: '2016-05-01', name: '王小虎', address: '1' },
        { date: '2016-05-03', name: '王小虎', address: '1' }
      ],
      currentPage4: 1,
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      ],
      dialogVisible: false,
      // 全部记录
      activeName: 'first',
      dialogVisibleAll: false,
      tableData1: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }
      ],
      // 公私转
      dialogVisibleConvert: false
    }
  },
  methods: {},
  mounted () { }
}
</script>

<style lang="scss"  scoped>
.mr {
  margin-right: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mr10 {
  margin-right: 10px;
}

// 表格区域
.tag-box {
  margin-top: 5px;
}
.fw600 {
  font-weight: 600;
}
.f14 {
  font-size: 14px;
}
.f16 {
  font-size: 16px;
}
.pagination-box {
  padding-right: -20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flex {
  display: flex;
  .img-box {
    margin-right: 20px;
    width: 150px;
    height: 120px;
    border-radius: 8px 8px 8px 8px;
  }
  .tag-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
.color2e4543 {
  color: #2e3543;
}
.colora7abba {
  color: #a7abba;
}
.mb6 {
  margin-bottom: 6px;
}
.title-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
  .all-total {
    font-size: 18px;
    font-weight: 500;
    color: #2e3543;
    span {
      font-size: 20px;
      color: #409eff;
    }
  }
  .right-tab {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 44px;
    background: #f7f8fc;
    border-radius: 4px;
    opacity: 1;
    border: 1px solid #f7f8fc;
    padding: 2px;
    div {
      position: relative;
      padding: 10px 30px;
      font-size: 14px;
      color: #a7abba;
      cursor: pointer;
    }
    .active {
      background: #ffffff;
      color: #409eff;
      border-radius: 4px;
      transition: all 0.3s;
    }
    .active::before {
      content: '';
      position: absolute;
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px;
      cursor: pointer;
      transition: all 0.5s;
    }
  }
}
</style>
