// 团队类型
export const TEAM_TYPE = [
  { value: 0, label: '分店', icon: 'building' },
  { value: 1, label: '团队', icon: 'users' }
]
// 报数收佣类型
export const ORDER_STATUS = [
  { value: 0, label: '未齐佣' },
  { value: 1, label: '坏账' },
  { value: 2, label: '齐佣' },
  { value: 3, label: '超佣' }
]

// 报数业绩类型
export const PERFORMANCE_TYPE = [
  { value: 0, label: '电商佣金' }
]

// 盘客拆分类型
export const CLIENT_TYPE = [
  { value: 0, label: '拆店经' },
  { value: 1, label: '拆合伙人店经' },
  { value: 2, label: '拆私客' },
  { value: 3, label: '拆盘主' },
  { value: 4, label: '拆区经' },
  { value: -1, label: '拆其他' }
]

// 合伙人拆分类型
export const PARTNER_TYPE = [
  { value: 0, label: '主单人' },
  { value: 1, label: '合伙人' },
  { value: 2, label: '主单人上级' },
  { value: 3, label: '合伙人上级' },
  { value: 4, label: '主单人拆自己' },
  { value: 5, label: '合伙人拆自己' }
]

// 报数实收类型
export const PAYMENT_TYPE = [
  { value: 0, label: '电商佣金' }
]

// 档案字段类型
export const PROJECT_FIELD_TYPE = [
  { value: 0, label: '文本' },
  // { value: 1, label: '文本域（可输入多行文本）' },
  // { value: 2, label: '富文本（拥有更多丰富的文本内容，如图片、网页）' },
  { value: 3, label: '日期' },
  // { value: 4, label: '日期（含时间）' },
  { value: 5, label: '下拉选项' },
  { value: 6, label: 'Radio选项' },
  { value: 7, label: '附件' },
  { value: 8, label: 'Check选项' }
]
