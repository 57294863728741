<template>
  <div>
    <div class="title-flex">
      <div class="all-total">共<span>567</span>条数据</div>
      <div class="right-tab">
        <div v-for="item in ['智能排序','按创建时间排序']" :key="item" :class="{active:current==item}" @click="current=item">{{item}}</div>
      </div>
    </div>
    <!-- table表格 -->
    <el-table :data="tableData" style="width: 100%;" class="mb20 hide-borders">
      <el-table-column prop="date" label="性别" width="120" fixed>
        <template slot-scope="scope">
          <el-image style="width: 80px; height: 80px" src="./../../../assets/img/madam.pngl" v-if="scope.row.date=='madam'"></el-image>
          <el-image style="width: 80px; height: 80px" src="./../../../assets/img/man.pngl" v-else></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="称呼/编号" width="300">
        <template slot-scope="scope">
          <div class="mb20 flex-end ">
            <div class="fw600 f16 mr20">陈女士</div>
            <div>2305K00001</div>
          </div>
          <el-tag size="medium" effect="dark" v-if="scope.row.date=='madam'"> 公 客 </el-tag>
          <el-tag size="medium" effect="dark" type="success" v-else> 私 客 </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="总价/需求类型">
        <template>
          <div class="color2e4543 mb6">1800-2600元/月</div>
          <div class="colora7abba">住宅</div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="面积/区域">
        <template>
          <div class="color2e4543 mb6">80~170m²</div>
          <div class="colora7abba">广州全市</div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="户型/楼层">
        <template>
          <div class="color2e4543 mb6">2室/3室/4室</div>
          <div class="colora7abba">1-6层</div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="时间/次数">
        <template>
          <div class="color2e4543 mb6">2023-05-12</div>
          <div class="colora7abba">2</div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="负责人/时间">
        <template>
          <div class="color2e4543 mb6">陈大星</div>
          <div class="colora7abba">2023-05-12</div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" width="100" align="center" fixed="right">
        <template>
          <div>
            <el-button type="text" size="mini" class="f14" @click="$root.navigate({name:'EstateCustomerViewAskRent'})">查看客源</el-button>
          </div>
          <div>
            <el-button type="text" size="mini" class="f14" @click="dialogVisibleConvert=true">公转私</el-button>
          </div>
          <div>
            <el-button type="text" size="mini" class="f14" @click="dialogVisibleAll=true">全部记录</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination-box ">
      <el-pagination background :current-page="currentPage4" :page-sizes="[20,40,80,100]" :page-size="20" layout="total, prev, pager, next, sizes" :total="100">
      </el-pagination>
    </div>

    <!-- 全部记录 -->
    <el-dialog title="全部记录" :visible.sync="dialogVisibleAll" width="70%">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="全部" name="first"></el-tab-pane>
        <el-tab-pane label="跟进" name="second"></el-tab-pane>
        <el-tab-pane label="带看" name="third"></el-tab-pane>
        <el-tab-pane label="查看" name="fourth"></el-tab-pane>
        <el-tab-pane label="其他" name="fourth1"></el-tab-pane>
      </el-tabs>
      <el-table :data="tableData1" border style="width: 100%">
        <el-table-column prop="date" label="操作时间  " width="180">
        </el-table-column>
        <el-table-column prop="name" label="操作人员" width="180">
        </el-table-column>
        <el-table-column prop="address" label="操作记录">
        </el-table-column>
        <el-table-column prop="address" label="图片">
          <template>
            <el-image style="width: 50px; height: 50px" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleAll = false" size="medium">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 公私转 -->
    <el-dialog title="提示" :visible.sync="dialogVisibleConvert" width="30%">
      <div>
        <div style="margin-bottom: 10px;">确定转为私客？</div>
        <div>（转为私客后，只有负责人和他上级们可以查看客户电话）</div>
      </div>
      <!-- <div>
        <div style="margin-bottom: 10px;">确定转为公客？</div>
        <div>（转为公客后，公司里所有人都可以直接看到客户电话）</div>
      </div> -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleConvert = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisibleConvert = false" size="medium">取 消</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'EstateCustomerAskRent',
  data () {
    return {
      current: '智能排序',
      tableData: [
        { date: 'man', name: '王小虎', address: '1' },
        { date: 'madam', name: '王小虎', address: '1' },
        { date: 'man', name: '王小虎', address: '1' },
        { date: 'madam', name: '王小虎', address: '1' }
      ],
      currentPage4: 1,
      // 全部记录
      activeName: 'first',
      dialogVisibleAll: false,
      dialogVisibleConvert: false,
      tableData1: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }
      ]
    }
  },
  methods: {},
  mounted () { }
}
</script>

<style lang="scss"  scoped>
.mr20 {
  margin-right: 20px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mr10 {
  margin-right: 10px;
}
// 表格区域
.tag-box {
  margin-top: 8px;
}
.fw600 {
  font-weight: 600;
}
.f14 {
  font-size: 14px;
}
.f16 {
  font-size: 16px;
}
.flex-end {
  display: flex;
  align-items: flex-end;
}
.pagination-box {
  padding-right: -20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flex {
  display: flex;
  .img-box {
    margin-right: 20px;
    width: 150px;
    height: 120px;
    border-radius: 8px 8px 8px 8px;
  }
  .tag-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
.color2e4543 {
  color: #2e3543;
}
.colora7abba {
  color: #a7abba;
}
.mb6 {
  margin-bottom: 6px;
}
.title-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
  .all-total {
    font-size: 18px;
    font-weight: 500;
    color: #2e3543;
    span {
      font-size: 20px;
      color: #409eff;
    }
  }
  .right-tab {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 44px;
    background: #f7f8fc;
    border-radius: 4px;
    opacity: 1;
    border: 1px solid #f7f8fc;
    padding: 2px;
    div {
      position: relative;
      padding: 10px 30px;
      font-size: 14px;
      color: #a7abba;
      cursor: pointer;
    }
    .active {
      background: #ffffff;
      color: #409eff;
      border-radius: 4px;
      transition: all 0.3s;
    }
    .active::before {
      content: '';
      position: absolute;
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px;
      cursor: pointer;
      transition: all 0.5s;
    }
  }
}
</style>
