const API = {
  KEY: 'OneForAll.RealEstate',
  PROJECT: {
    URL: '/api/EsProjects'
  },
  PROJECT_SETTING: {
    URL: '/api/EsProjectSettings',
    FIELD: '/api/EsProjectSettings/{id}/Fields',
    BATCH_SORT: '/api/EsProjectSettings/Batch/SortNumber',
    FIELD_DELETE: '/api/EsProjectSettings/{id}/Fields/{fieldId}',
    FIELD_ENABLE: '/api/EsProjectSettings/{id}/Fields/{fieldId}/IsEnabled',
    FIELD_BATCH_SORT: '/api/EsProjectSettings/{id}/Fields/Batch/SortNumber'
  }
}

export default API
