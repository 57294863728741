<template>
  <div class="CustomerSource">

    <div class="search-tab">
      <el-tabs v-model="radio" class="tab-box">
        <el-tab-pane name="1">
          <span slot="label">
            <i class="el-icon-s-home tab-icon"></i>
            <span> 求 购 </span>
          </span>
        </el-tab-pane>
        <el-tab-pane label=" 租 房 " name="2">
          <span slot="label">
            <i class="el-icon-s-home tab-icon"></i>
            <span> 求 租 </span>
          </span>
        </el-tab-pane>
      </el-tabs>
      <!-- 新增 -->
      <el-button class="add-btn" type="primary" size="mini" @click="clickAdd">
        {{['','新增求购客户','新增求租客户'][radio]}}
      </el-button>

      <div class="flex-box">
        <div class="mb24">房产概况：</div>
        <el-select v-model="value1" placeholder="用途" size="small" clearable class="mr50 mb24" style="width:180px">
          <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="value2" placeholder="销售状况" size="small" clearable class="mr50 mb24" style="width:180px">
          <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="value3" placeholder="户型" size="small" clearable class="mr50 mb24" style="width:180px">
          <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>

      <div class="flex-box">
        <div class="mb24">房产价格：</div>
        <el-select v-model="value4" placeholder="面积" size="small" clearable class="mr50 mb24" style="width:180px">
          <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="value4" placeholder="面积" size="small" clearable class="mr20 mb24" style="width:120px">
          <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <div class="mb24">
          <el-input v-model="input" placeholder="最低" size="small" style="width:80px"></el-input>
          <span class="mlr5">-</span>
          <el-input v-model="input1" placeholder="最高" size="small" style="width:80px"></el-input>
        </div>
      </div>

      <el-collapse-transition>
        <div class="flex-box" v-show="show">
          <div class="pb24">其他信息：</div>
          <el-cascader placeholder="区域" size="small" clearable class="mr50 pb24" style="width:180px"></el-cascader>
          <el-select v-model="value1" placeholder="小区" size="small" clearable class="mr50 pb24" style="width:180px">
            <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input v-model="input" size="small" class="pb24 prepend-input" clearable style="width:180px">
            <span slot="prepend" class="prepend-text">关键字</span>
          </el-input>
        </div>
      </el-collapse-transition>

      <div class="btn-group">
        <el-button size="mini"> 重 置 </el-button>
        <el-button type="primary" size="mini"> 提 交 </el-button>
      </div>

    </div>
    <div class="trapezoid" @click="show=!show">
      <span> {{ show?'收起':'展开更多'}}</span>
      <i class="el-icon-arrow-down" :class="show?'arrowRotate':'arrow'"></i>
    </div>
    <div class="table-box">
      <!-- 求购 -->
      <AskBuy v-if="radio==='1'"></AskBuy>
      <!-- 求租 -->
      <AskRent v-if="radio==='2'"></AskRent>
    </div>
  </div>
</template>

<script>
import { CUSTOMER } from '../../../router/estate-router'
import AskBuy from './AskBuy.vue'
import AskRent from './AskRent.vue'
export default {
  name: CUSTOMER.name,
  components: { AskBuy, AskRent },
  data () {
    return {
      show: false,
      radio: '1',
      options1: [
        { value: '选项1', label: '住宅' },
        { value: '选项2', label: '别墅' }
      ],
      value1: '',
      options2: [
        { value: '选项1', label: '在售' },
        { value: '选项2', label: '待售' },
        { value: '选项3', label: '售罄' }
      ],
      value2: '',
      options3: [
        { value: '选项1', label: '1室' },
        { value: '选项2', label: '2室' },
        { value: '选项3', label: '3室' },
        { value: '选项4', label: '4室' },
        { value: '选项5', label: '5室' },
        { value: '选项5', label: '5室以上' }
      ],
      value3: '',
      options4: [
        { value: '选项1', label: '50m²以下' },
        { value: '选项2', label: '50~70m²' },
        { value: '选项3', label: '70~90m²' },
        { value: '选项4', label: '90~110m²' },
        { value: '选项5', label: '110~130m²' },
        { value: '选项6', label: '130~150m²' },
        { value: '选项7', label: '150m²以上' }
      ],
      value4: ''
    }
  },
  methods: {
    clickAdd () {
      if (this.radio === '1') {
        this.$root.navigate({ name: 'EstateCustomerAddAskBuy' })
      } else {
        this.$root.navigate({ name: 'EstateCustomerAddAskRent' })
      }
    }
  },
  mounted () { }
}
</script>

<style >
.el-dialog,
.el-message-box {
  border-radius: 6px !important;
}
.el-input-group__append,
.el-input-group__prepend {
  padding: 0 10px !important;
}
.arrow {
  transition: all 0.5s;
  transform: rotate(0deg);
  font-weight: 900;
}
.arrowRotate {
  transition: all 0.5s;
  font-weight: 900;
  transform: rotate(-180deg);
}
.prepend-input .el-input-group__prepend {
  background-color: #fff;
  color: #2e3543;
  border-right: 1px solid transparent;
}
.prepend-input .el-input__inner {
  border-left: 1px solid transparent;
}
.prepend-text {
  padding-right: 9px;
  border-right: 1px solid #dcdfe6;
}
.hide-borders .el-table__header-wrapper,
.hide-borders .el-table__body-wrapper {
  border: none;
}
.hide-borders.el-table th.is-leaf {
  border: none;
}
.hide-borders.el-table th,
.hide-borders .el-table__header {
  background: #f7f8fc;
  border-radius: 8px;
  color: #2e3543;
}
.hide-borders .el-table__body tbody tr td {
  border: none;
}
.tab-box .el-tabs__active-bar,
.tab-box .el-tabs__nav-wrap:after {
  height: 1px;
}
</style>
<style lang="scss" scoped>
.search-tab {
  position: relative;
  padding: 20px 30px 6px;
  background: #ffffff;
  border-radius: 16px;
  color: #2e3543;
  .tab-box {
    margin-bottom: 15px;
  }
  .btn-group {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
  .add-btn {
    position: absolute;
    right: 30px;
    top: 30px;
  }
  .tab-icon {
    margin-right: 10px;
  }
  /deep/ .el-tabs__item {
    color: #a7abba;
    height: 45px;
    line-height: 45px;
  }
  /deep/ .el-tabs__item:hover {
    color: #409eff;
  }
  /deep/ .el-tabs__item.is-active {
    color: #409eff;
  }
}
.mr50 {
  margin-right: 50px;
}
.mb24 {
  margin-bottom: 24px;
}
.pb24 {
  padding-bottom: 24px;
}
.mr20 {
  margin-right: 20px;
}
.mlr5 {
  margin: 0 5px;
}
.trapezoid {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a7abba;
  margin: 0 auto;
  margin-top: -3px;
  position: relative;
  width: 122px;
  height: 28px;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  margin-bottom: 30px;
  cursor: pointer;
  span {
    margin-right: 4px;
  }
}
.trapezoid::before {
  content: '';
  position: absolute;
  top: 0;
  left: -24px;
  width: 0;
  height: 0;
  border-top: 14px solid #fff;
  border-right: 14px solid #fff;
  border-left: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-radius: 0 0 10px 0;
  cursor: pointer;
}
.trapezoid::after {
  content: '';
  position: absolute;
  top: 0;
  right: -24px;
  width: 0;
  height: 0;
  border-top: 14px solid #fff;
  border-right: 14px solid transparent;
  border-left: 14px solid #fff;
  border-bottom: 14px solid transparent;
  border-radius: 0 0 0 10px;
  cursor: pointer;
}
.flex-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.table-box {
  background: #ffffff;
  border-radius: 16px;
  padding: 20px 30px;
}
</style>
