/* eslint-disable */
import API from './apis/estate-api'
import ChildRoutes from './router/router'
import './assets/css/estate-theme.scss'
// import { WELCOME } from './router/estate-router'

// --- 微服务环境 ---
if (window.AppData) {
  window.AppData.registerChildRoutes(ChildRoutes)
  window.AppData.registerModule(API.KEY)
}
