<template>
  <div class="view-box">

    <div class="flex">
      <div class="view-right">
        <div class="image-box">
          <el-image style="width: 400px; height: 300px" :src="url" :preview-src-list="srcList"></el-image>
        </div>
        <div class="dot-box">
          <i class="el-icon-d-arrow-left" @click="clickDot(0)"></i>
          <el-image :class="{active:currentIndex==index}" class="dot-img" :src="item" v-for="item,index in srcList" :key="index" @click="clickDotImg(item,index)"></el-image>
          <i class="el-icon-d-arrow-right" @click="clickDot(1)"></i>
        </div>
      </div>
      <div class="view-left">
        <div class="left-header">
          <div class="header-name">
            <div class="name-text">这里是楼盘名称xxxxxxxxxxxx</div>
            <el-tag type="info" effect="dark" size="small" class="mr20">住宅</el-tag>
            <div class="f12">编号：XF000001</div>
          </div>
          <el-dropdown placement="bottom" @command="command">
            <el-button type="primary" size="medium">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="dialogVisibleUp">写跟进</el-dropdown-item>
              <el-dropdown-item command="dialogVisibleView">写带看</el-dropdown-item>
              <el-dropdown-item command="dialogVisiblePeople">变更负责人</el-dropdown-item>
              <el-dropdown-item command="edit">编辑楼盘</el-dropdown-item>
              <el-dropdown-item command="dialogVisibleEdit">编辑户型</el-dropdown-item>
              <el-dropdown-item command="dialogVisibleVideo">编辑图片视频</el-dropdown-item>
              <el-dropdown-item command="delete">删除楼盘</el-dropdown-item>
              <el-dropdown-item command="dialogVisibleAll">全部记录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="price-row">
          <div class="mr20">
            <span>参考均价</span>
            <span class="f24">21000</span>
            <span class="fe615a">元/m²</span>
          </div>
          <div class="mr20">
            <span>参考总价</span>
            <span class="f24">175</span>
            <span class="fe615a">万/套</span>
          </div>
          <el-tag effect="plain" class="mr20">在售</el-tag>
        </div>
        <div class="tag-box">
          <el-tag effect="plain" class="mr10" type="success" size="small">现房</el-tag>
          <el-tag effect="plain" class="mr10" type="success" size="small">近地铁</el-tag>
          <el-tag effect="plain" class="mr10" type="success" size="small">学区房</el-tag>
          <el-tag effect="plain" class="mr10" type="success" size="small">绿化率高 </el-tag>
        </div>
        <el-row class="mb20">
          <el-col :span="10">开盘：2023-01-30</el-col>
          <el-col :span="10">装修：普装、精装</el-col>
        </el-row>
        <el-row class="mb20">
          <el-col :span="10">面积：85-175 ㎡</el-col>
          <el-col :span="10">户型：两居室、三居室 <span class="all-btn" @click="dialogVisible=true">全部户型</span> </el-col>
        </el-row>
        <el-row class="mb20">
          <el-col :span="24">地址：北京市顺义区水色时光西路西侧</el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <div class="flex">
              <div class="people-box">
                <i class="el-icon-user"></i>
              </div>
              <div>
                <div class="shop-name">广东A店</div>
                <div class="mb20">陈星</div>
                <div>负责人</div>
              </div>
            </div>
          </el-col>
          <el-col :span="10">
            <div class="flex">
              <div class="people-box">
                <i class="el-icon-user"></i>
              </div>
              <div>
                <div class="shop-name">广东A店</div>
                <div class="mb20">陈星</div>
                <div>录入人</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="info-title">基本参数</div>
    <div class="c666">
      <div class="flex-wrap ">
        <div>
          <span class="title-left">省市区域：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
        <div>
          <span class="title-left">产权年限：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
        <div>
          <span class="title-left">最近交房：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
      </div>
      <div class="flex-wrap ">
        <div>
          <span class="title-left">开发商：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
        <div>
          <span class="title-left">总建筑面积：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
        <div>
          <span class="title-left">容积率：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
      </div>
      <div class="flex-wrap ">
        <div>
          <span class="title-left">绿化率：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
        <div>
          <span class="title-left">小区总户数：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
        <div>
          <span class="title-left">小区栋数：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
      </div>
      <div class="flex-wrap ">
        <div>
          <span class="title-left">物业名称：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
        <div>
          <span class="title-left">物业费用：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
        <div>
          <span class="title-left">车位数量：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
      </div>
    </div>

    <div class="info-title color409eff" @click="show=!show">
      其他
      <i class="el-icon-arrow-down" :class="show?'arrowRotate':'arrow'"></i>
    </div>
    <el-collapse-transition>
      <div class="c666" v-if="show">
        <div class="flex-wrap ">
          <div>
            <span class="title-left">负责人：</span>
            <span class="title-desc">xxxxxxxxxxxxxxxx</span>
          </div>
          <div>
            <span class="title-left">佣金：</span>
            <span class="title-desc">xxxxxxxxxxxxxxxx</span>
          </div>
          <div>
            <span class="title-left">期限：</span>
            <span class="title-desc">xxxxxxxxxxxxxxxx</span>
          </div>
        </div>
      </div>
    </el-collapse-transition>
    <el-dialog title="全部户型" :visible.sync="dialogVisible" width="50%" top="2vh">
      <div class="all-house">
        <div class="house-item" v-for="item,index in allsrc" :key="index">
          <div class="item-image">
            <i class="el-icon-d-arrow-left left" @click="clickAll(0,item)"></i>
            <el-image style="width: 240px; height: 180px" :src="item.srcList[item.currentIndex]" :preview-src-list="item.srcList"></el-image>
            <i class="el-icon-d-arrow-right right" @click="clickAll(1,item)"></i>
            <div class="desc-box">三房朝南、厨卫全明、南北通透、品牌地产、低密度</div>
          </div>
          <div class="flex-sp">
            <span>141平户型</span>
            <span>3室2厅2卫</span>
          </div>
          <div class="flex-sp">
            <span class="fe615a">900万</span>
            <span>141m²</span>
          </div>
          <div class="flex-sp">
            <el-tag type="success" effect="dark" size="small">待售</el-tag>
            <!-- <el-tag effect="dark" size="small">在售</el-tag>
            <el-tag type="info" effect="dark" size="small">售罄</el-tag> -->
            <span>东南</span>
          </div>

        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="medium">上一页</el-button>
        <el-button type="primary" size="medium">下一页</el-button>
      </span>
    </el-dialog>

    <!-- 写跟进 -->
    <el-dialog title="写跟进" :visible.sync="dialogVisibleUp" width="50%">
      <el-form label-width="120px">
        <el-form-item label="跟进内容：">
          <el-input type="textarea" :rows="8" placeholder="请输入内容" maxlength="300" show-word-limit>
          </el-input>
        </el-form-item>
        <el-form-item label="图片上传：">
          <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>最多5张图片，单张大小不超过10M</div>
          <el-dialog :visible.sync="Visible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleUp = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisibleUp = false" size="medium">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 写带看 -->
    <el-dialog title="写带看" :visible.sync="dialogVisibleView" width="50%">
      <el-form label-width="120px" :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item label="带看客户：" prop="name">
          <el-input placeholder="请输入内容" prefix-icon="el-icon-search" style="width:80%"></el-input>
        </el-form-item>
        <el-form-item label="带看人：" prop="name">
          <el-select placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="带看日期：" prop="name">
          <div style="display:flex;align-items:center">
            <el-date-picker type="date" placeholder="选择日期" style="margin-right: 10px;"></el-date-picker>
            <el-radio-group size="medium">
              <el-radio-button label="上午">上午</el-radio-button>
              <el-radio-button label="下午">下午</el-radio-button>
              <el-radio-button label="晚上">晚上</el-radio-button>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="反馈：">
          <el-input type="textarea" :rows="8" placeholder="请输入内容" maxlength="300" show-word-limit>
          </el-input>
        </el-form-item>
        <el-form-item label="图片：">
          <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>最多5张图片，单张大小不超过10M</div>
          <el-dialog :visible.sync="Visible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleView = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisibleView = false" size="medium">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 图片视频管理 -->
    <el-dialog title="图片视频管理" :visible.sync="dialogVisibleVideo" width="50%">
      <el-form label-width="120px">
        <el-form-item label="视频：">
          <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>每个房源免费支持上传1个视频，限定大小不超过10M</div>
        </el-form-item>
        <el-form-item label="图片：">
          <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>最多5张图片，单张大小不超过10M</div>
          <el-dialog :visible.sync="Visible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleVideo = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisibleVideo = false" size="medium">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 编辑户型 -->
    <el-dialog title="编辑户型" :visible.sync="dialogVisibleEdit" width="70%" top="3vh">
      <el-button type="primary" size="medium" style="margin-bottom:20px" @click="add">新增户型</el-button>
      <div class="dialog-main">
        <el-divider></el-divider>
        <el-form label-width="120px" :model="Form" :rules="rules">
          <div v-for="item,index in Form.arr" :key="item">

            <div style="display:flex;justify-content:space-between;align-items:center">
              <div style="display:flex;flex:1">
                <el-form-item label="户型名称：" prop="name" style="flex:0.8">
                  <el-input placeholder="请输入内容" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="销售状态：" prop="name">
                  <el-select placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div style="cursor: pointer;font-size:18px;margin-bottom:20px;color: #ff0000;" @click="del(index)">删除</div>
            </div>

            <el-form-item label="户型：" prop="name">
              <el-select placeholder="请选择" style="width:100px">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <span style="margin:0 5px">室</span>
              <el-select placeholder="请选择" style="width:100px">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <span style="margin:0 5px">厅</span>
              <el-select placeholder="请选择" style="width:100px">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <span style="margin:0 5px">卫</span>
              <el-select placeholder="请选择" style="width:100px">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <span style="margin:0 5px">阳</span>
            </el-form-item>

            <div style="display:flex">
              <el-form-item label="面积：">
                <el-input placeholder="请输入内容">
                  <template slot="append">m²</template>
                </el-input>
              </el-form-item>
              <el-form-item label="参考总价：">
                <el-input placeholder="请输入内容">
                  <template slot="append">万元/套</template>
                </el-input>
              </el-form-item>
              <el-form-item label="朝向：">
                <el-select placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>

            <el-form-item label="图片：">
              <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
                <i class="el-icon-plus"></i>
              </el-upload>
              <div>最多5张图片，单张大小不超过10M</div>
              <el-dialog :visible.sync="Visible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </el-form-item>
            <el-divider></el-divider>
          </div>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleEdit = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisibleEdit = false" size="medium">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 变更负责人 -->
    <el-dialog title="变更负责人" :visible.sync="dialogVisiblePeople" width="40%">
      <el-form label-width="120px">
        <el-form-item label="当前负责人：">
          <span>xxxxxxxx</span>
        </el-form-item>
        <el-form-item label="更改为：">
          <el-select placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisiblePeople = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisiblePeople = false" size="medium">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 全部记录 -->
    <el-dialog title="全部记录" :visible.sync="dialogVisibleAll" width="70%">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="全部" name="first"></el-tab-pane>
        <el-tab-pane label="跟进" name="second"></el-tab-pane>
        <el-tab-pane label="带看" name="third"></el-tab-pane>
        <el-tab-pane label="查看" name="fourth"></el-tab-pane>
        <el-tab-pane label="其他" name="fourth1"></el-tab-pane>
      </el-tabs>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="date" label="操作时间  " width="180">
        </el-table-column>
        <el-table-column prop="name" label="操作人员" width="180">
        </el-table-column>
        <el-table-column prop="address" label="操作记录">
        </el-table-column>
        <el-table-column prop="address" label="图片" width="80">
          <template>
            <el-image style="width: 50px; height: 50px" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleAll = false" size="medium">关 闭</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'EstateProjectViewNewHouse',
  data () {
    return {
      currentIndex: 0,
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: [
        'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      ],
      allsrc: [
        {
          currentIndex: 0,
          url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          srcList: [
            'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
            'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
            'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
            'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
          ]
        },
        {
          currentIndex: 0,
          url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          srcList: [
            'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
            'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
            'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
            'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
          ]
        },
        {
          currentIndex: 0,
          url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          srcList: [
            'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
            'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
            'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
            'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
          ]
        },
        {
          currentIndex: 0,
          url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          srcList: [
            'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
            'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
            'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
            'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
          ]
        },
        {
          currentIndex: 0,
          url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          srcList: [
            'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
            'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
            'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
            'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
          ]
        },
        {
          currentIndex: 0,
          url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          srcList: [
            'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
            'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
            'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
            'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
          ]
        }
      ],
      show: false,
      dialogVisible: false,
      // 写跟进
      dialogVisibleUp: false,
      dialogImageUrl: '',
      Visible: false,
      // 写带看
      dialogVisibleView: false,
      ruleForm: {
        name: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      },
      options: [{ value: '选项1', label: '黄金糕' }],
      // 图片视频管理
      dialogVisibleVideo: false,
      // 编辑户型
      dialogVisibleEdit: false,
      Form: {
        arr: [
          {
            name: ''
          }
        ]
      },
      // 变更负责人
      dialogVisiblePeople: false,
      // 全部记录
      activeName: 'first',
      dialogVisibleAll: false,
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }
      ]
    }
  },
  methods: {
    clickDotImg (item, index) {
      this.url = item
      this.currentIndex = index
    },
    clickDot (flag) {
      if (flag) {
        if (this.currentIndex === this.srcList.length - 1) {
          this.currentIndex = 0
        } else {
          this.currentIndex++
        }
      } else {
        if (this.currentIndex === 0) {
          this.currentIndex = this.srcList.length - 1
        } else {
          this.currentIndex--
        }
      }
      this.url = this.srcList[this.currentIndex]
    },
    clickAll (flag, item) {
      if (flag) {
        if (item.currentIndex === item.srcList.length - 1) {
          item.currentIndex = 0
        } else {
          item.currentIndex++
        }
      } else {
        if (item.currentIndex === 0) {
          item.currentIndex = item.srcList.length - 1
        } else {
          item.currentIndex--
        }
      }
    },
    command (item) {
      if (item === 'edit') {
        return this.$root.navigate({ name: 'EstateProjectAddNewHouse' })
      }
      if (item === 'delete') {
        return this.$confirm(
          '确定删除楼盘（ XF001001佛山顺德碧桂园一期）?（删除后将不能恢复，请谨慎操作）',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
      }
      this[item] = true
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    add () {
      this.Form.arr.push({ name: '' })
    },
    del (index) {
      this.Form.arr.splice(index, 1)
    }
  },
  mounted () { }
}
</script>
<style lang="scss" scoped>
.view-box {
  border-radius: 6px;
  background-color: #fff;
  padding: 20px;
  font-size: 14px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
}
.mr10 {
  margin-right: 10px;
}
.dot-box {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  i {
    font-size: 36px;
    cursor: pointer;
  }
}
.view-right {
  margin-right: 20px;
}
.view-left {
  flex: 1;
  .left-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-name {
      display: flex;
      align-items: center;
      .name-text {
        font-size: 18px;
        font-weight: 900;
        margin-right: 20px;
      }
      .f12 {
        font-size: 12px;
      }
      .mr20 {
        margin-right: 20px;
      }
    }
  }
}
.fe615a {
  color: #fe615a;
}
.price-row {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .f24 {
    font-size: 36px;
    color: #fe615a;
    font-weight: 900;
    margin: 0 10px;
  }

  .mr20 {
    margin-right: 20px;
  }
  .el-tag {
    padding: 0 25px;
  }
}
.tag-box {
  margin: 15px 0;
}
.mb20 {
  margin-bottom: 20px;
}
.all-btn {
  margin-left: 20px;
  cursor: pointer;
  color: #409eff;
}
.people-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 118px;
  height: 118px;
  background: #409eff;
  font-size: 100px;
  color: #fff;
  border-radius: 8px;
  margin-right: 20px;
}
.shop-name {
  margin: 20px 0 15px;
  font-weight: 900;
}
.info-title {
  font-weight: 900;
  font-size: 18px;
  margin: 20px 0;
}
.c666 {
  color: #666666;
}
.mb30 {
  margin-bottom: 30px;
}
.color409eff {
  color: #409eff;
  cursor: pointer;
}
.all-house {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 688px;
  overflow-y: auto;
  overflow-x: hidden;
  .house-item {
    margin: 0 2% 20px;
    .item-image {
      position: relative;
      .left {
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
        z-index: 10;
        color: #000;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.7);
        padding: 10px 2px 10px 0;
      }
      .right {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
        z-index: 10;
        color: #000;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.7);
        padding: 10px 0 10px 2px;
      }
      .desc-box {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 5px;
        background: rgba(255, 255, 255, 0.7);
        font-size: 12px;
        color: #409eff;
      }
    }
    .flex-sp {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
    }
  }
}
.image-box {
  box-sizing: border-box;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 6px;
}
.dot-img {
  cursor: pointer;
  width: 50px;
  height: 50px;
}
.active {
  box-sizing: border-box;
  border: 2px solid red;
}
.dialog-main {
  padding-right: 20px;
  height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  > div {
    display: flex;
    margin-bottom: 30px;
  }
  .title-left {
    flex-shrink: 0;
    width: 100px;
    text-align: right;
  }
  .title-desc {
    width: 300px;
    word-wrap: break-word;
    word-break: break-all;
  }
}
</style>
