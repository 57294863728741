<template>
  <div class="box">
    <div class="title-info">客户-求购</div>
    <div class="title-desc">基本信息</div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
      <div class="flex">
        <div>
          <el-form-item label="客户称呼:" prop="name">
            <el-input v-model="ruleForm.name" size="medium" clearable placeholder="请输入客户称呼"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="客户电话:" prop="tel">
            <el-input v-model="ruleForm.tel" size="medium" clearable placeholder="请输入电话" class="mr5"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="性别:" prop="sex">
            <div class="width100" style="display:flex;align-items:center">
              <el-radio v-model="ruleForm.sex" label="1">男</el-radio>
              <el-radio v-model="ruleForm.sex" label="2">女</el-radio>
              <el-button icon="el-icon-plus" size="medium" @click="addOwner">添加备用联系</el-button>
            </div>
          </el-form-item>
        </div>
      </div>

      <div class="flex" v-for="item,index in owner" :key="index">
        <div>
          <el-form-item label="客户称呼:" prop="name">
            <el-input v-model="ruleForm.name" size="medium" clearable placeholder="请输入客户称呼"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="客户电话:" prop="tel">
            <el-input v-model="ruleForm.tel" size="medium" clearable placeholder="请输入电话" class="mr5"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="性别:" prop="value">
            <div class=" width100" style="display:flex;align-items:center">
              <el-radio v-model="ruleForm.sex" label="1">男</el-radio>
              <el-radio v-model="ruleForm.sex" label="2">女</el-radio>
              <el-button icon="el-icon-error" type="warning" size="medium" @click="delOwner(index)"></el-button>
            </div>
          </el-form-item>
        </div>
      </div>

      <div class="flex">
        <div>
          <el-form-item label="等级:" prop="lv">
            <el-select v-model="ruleForm.lv" placeholder="" size="medium" class="width100">
              <el-option v-for="item in lvArr" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="客别:" prop="type">
            <el-select v-model="ruleForm.type" placeholder="" size="medium" class="width100">
              <el-option v-for="item in typeArr" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="来源渠道:" prop="zong">
            <el-select v-model="ruleForm.source" placeholder="" size="medium" class="width100">
              <el-option v-for="item in sourceArr" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>

      <div class="flex">
        <div>
          <el-form-item label="客户获得时间:" prop="value">
            <el-date-picker v-model="ruleForm.time" type="date" placeholder="选择日期" class="width100"></el-date-picker>
          </el-form-item>
        </div>
      </div>

      <div class="flex">
        <div>
          <el-form-item label="备注:" prop="checkList">
            <el-input type="textarea" :autosize="{ minRows: 6}" placeholder="请输入" v-model="ruleForm.textarea" maxlength="500" show-word-limit>
            </el-input>
          </el-form-item>
        </div>
      </div>

      <div class="title-desc">客户需求</div>

      <div class="flex">
        <div>
          <el-form-item label="需求类型:" prop="demand">
            <el-select v-model="ruleForm.demand" placeholder="请选择" size="medium" class="width100">
              <el-option v-for="item in demandArr" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="省市:" prop="value">
            <el-cascader size="medium" class="width100" v-model="ruleForm.city"></el-cascader>
          </el-form-item>
        </div>
      </div>

      <el-form-item label="区域:" prop="checkList">
        <el-checkbox-group v-model="ruleForm.checkList">
          <el-checkbox :label="-1" @change="change">全选</el-checkbox>
          <el-checkbox :label="index" v-for="item,index in checkArr" :key="index">{{item}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <div class="flex">
        <div>
          <el-form-item label="装修:" prop="decoration">
            <el-select v-model="ruleForm.decoration" placeholder="请选择" size="medium" class="width100">
              <el-option v-for="item in ruleFormArr" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div style="width:auto">
          <el-form-item label="意向户型:" prop="check">
            <el-checkbox-group v-model="ruleForm.check">
              <el-checkbox :label="index" v-for="item,index in checkType" :key="index">{{item}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </div>

      <div class="flex">
        <div>
          <el-form-item label="商圈:" prop="business">
            <el-input v-model="ruleForm.business" size="medium" clearable placeholder="请输入"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="小区:" prop="community">
            <el-input v-model="ruleForm.community" size="medium" clearable placeholder="请输入"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="楼层:">
            <div style="display:flex;align-items:center">
              <el-input v-model="ruleForm.minbuilding" size="medium" placeholder="" style="width:48%"></el-input>
              <span class="mr">—</span>
              <el-input v-model="ruleForm.maxbuilding" size="medium" placeholder="" style="width:48%"></el-input>
              <span class="mr">层</span>
            </div>
          </el-form-item>
        </div>
      </div>

      <div class="flex">
        <div>
          <el-form-item label="首付预算:">
            <div style="display:flex;align-items:center">
              <el-input v-model="ruleForm.minbudget" size="medium" placeholder="" style="width:48%"></el-input>
              <span class="mr">—</span>
              <el-input v-model="ruleForm.maxbudget" size="medium" placeholder="" style="width:48%"></el-input>
              <span class="mr">万</span>
            </div>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="面积:" prop="zong">
            <div style="display:flex;align-items:center">
              <el-input v-model="ruleForm.minarea" size="medium" placeholder="" style="width:48%"></el-input>
              <span class="mr">—</span>
              <el-input v-model="ruleForm.maxarea" size="medium" placeholder="" style="width:48%"></el-input>
              <span class="mr">m²</span>
            </div>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="总价:" prop="zong">
            <div style="display:flex;align-items:center">
              <el-input v-model="ruleForm.minprice" size="medium" placeholder="" style="width:48%"></el-input>
              <span class="mr">—</span>
              <el-input v-model="ruleForm.maxprice" size="medium" placeholder="" style="width:48%"></el-input>
              <span class="mr">万</span>
            </div>
          </el-form-item>
        </div>
      </div>

      <div class="flex">
        <div>
          <el-form-item label="是否有车:" prop="zong">
            <el-radio v-model="ruleForm.car" label="1">是</el-radio>
            <el-radio v-model="ruleForm.car" label="2">否</el-radio>
          </el-form-item>
        </div>
        <div style="width:auto">
          <el-form-item label="目的:" prop="purpose">
            <el-checkbox-group v-model="ruleForm.purpose">
              <el-checkbox :label="index" v-for="item,index in purposeArr" :key="index">{{item}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </div>

      <div class="flex-c">
        <el-button size="medium">重置</el-button>
        <el-button type="primary" size="medium">确定</el-button>
      </div>

    </el-form>
  </div>
</template>

<script>
export default {
  name: 'EstateCustomerAddAskBuy',
  data () {
    return {
      owner: [],
      lvArr: [],
      typeArr: [],
      sourceArr: [],
      demandArr: [],
      ruleFormArr: [],
      checkArr: ['番禺', '天河', '海珠', '荔湾', '越秀', '从化', '花都', '增城', '南沙', '白云'],
      checkType: ['1室', '2室', '3室', '4室', '5室', '5室以上'],
      purposeArr: ['换房', '结婚', '教学', '投资', '其他'],
      ruleForm: {
        name: '',
        tel: '',
        sex: '1',
        lv: '',
        type: '',
        source: '',
        time: '',
        textarea: '',
        demand: '',
        city: '',
        checkList: [],
        decoration: '',
        check: [],
        business: '',
        community: '',
        minbuilding: '',
        maxbuilding: '',
        minbudget: '',
        maxbudget: '',
        minarea: '',
        maxarea: '',
        minprice: '',
        maxprice: '',
        car: '1',
        purpose: []
      },
      rules: {
        name: [{ required: true, message: '请输入客户名称', trigger: 'blur' }],
        tel: [{ required: true, message: '请输入客户电话', trigger: 'blur' }],
        lv: [{ required: true, message: '请选择等级', trigger: 'blur' }],
        type: [{ required: true, message: '请选择客别', trigger: 'blur' }],
        demand: [{ required: true, message: '请选择需求类型', trigger: 'blur' }]
      }
    }
  },
  methods: {
    change (value) {
      if (value) {
        this.checkArr.forEach((item, index) => {
          this.ruleForm.checkList.push(index)
        })
      } else {
        this.ruleForm.checkList = []
      }
    },
    addOwner () {
      if (this.owner.length === 4) {
        this.$message({
          message: '最多可添加5个客户信息',
          type: 'warning'
        })
      } else {
        this.owner.push({ name: '' })
      }
    },
    delOwner (index) {
      this.owner.splice(index, 1)
    }
  },
  mounted () { }
}
</script>

<style lang="scss" scoped>
.box {
  border-radius: 6px;
  background-color: #fff;
  padding: 20px;
  .title-info {
    color: #409eff;
    font-size: 16px;
    font-weight: 600;
  }
  .title-desc {
    font-weight: 600;
    font-size: 14px;
    margin: 20px;
  }
  .flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > div {
      width: 500px;
    }
  }
  .mr {
    margin: 0 5px;
  }
  .mr5 {
    margin-right: 5px;
  }
  .width100 {
    width: 100%;
  }
  .flex-c {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    .el-button--medium {
      padding: 10px 45px;
    }
  }
}
</style>
