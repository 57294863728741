<template>
  <div class="box">
    <div class="title-info">新增二手房-住宅</div>
    <div class="title-desc">核心信息</div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <div class="flex">
        <div>
          <el-form-item label="业主姓名:" prop="name">
            <el-input v-model="ruleForm.name" size="medium" clearable placeholder="请输入业主姓名"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="业主电话:" prop="name">
            <div class=" width100" style="display:flex">
              <el-input v-model="ruleForm.value" size="medium" clearable placeholder="请输入电话" class="mr5"></el-input>
              <el-button icon="el-icon-plus" size="medium" @click="addOwner">添加业主信息</el-button>
            </div>
          </el-form-item>
        </div>
      </div>

      <div class="flex" v-for="item,index in owner" :key="index">
        <div>
          <el-form-item label="业主姓名:" prop="name">
            <el-input v-model="ruleForm.name" size="medium" clearable placeholder="请输入业主姓名"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="业主电话:" prop="name">
            <div class=" width100" style="display:flex">
              <el-input v-model="ruleForm.value" size="medium" clearable placeholder="请输入电话" class="mr5"></el-input>
              <el-button icon="el-icon-error" type="warning" size="medium" @click="delOwner(index)"></el-button>
            </div>
          </el-form-item>
        </div>
      </div>

      <div class="flex">
        <div>
          <el-form-item label="小区:" prop="state">
            <el-input v-model="ruleForm.name" size="medium" clearable placeholder="请输入小区"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="栋座:" prop="name">
            <el-input v-model="ruleForm.dan" size="medium" clearable placeholder="请输入" style="width:70%"></el-input>
            <el-select v-model="optval" placeholder="" size="medium" style="width:30%">
              <el-option v-for="item in opt" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="单元:" prop="zong">
            <el-input v-model="ruleForm.zong" size="medium" clearable placeholder="请输入" style="width:70%"></el-input>
            <el-select v-model="zoval" placeholder="" size="medium" style="width:30%">
              <el-option v-for="item in zoopt" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>

      <div class="flex">
        <div>
          <el-form-item label="房号:" prop="state">
            <el-input v-model="ruleForm.name" size="medium" clearable placeholder="多房号则用英文逗号隔开"></el-input>
          </el-form-item>
        </div>
      </div>

      <div class="title-desc">基本信息</div>

      <div class="flex">
        <div>
          <el-form-item label="房源等级:" prop="name">
            <el-select v-model="year" placeholder="请选择" size="medium" class="width100">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="盘别:" prop="name">
            <el-select v-model="year" placeholder="请选择" size="medium" class="width100">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="类型:" prop="value">
            <el-select v-model="year" placeholder="请选择" size="medium" class="width100">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>

      <div class="flex">
        <div>
          <el-form-item label="价格:" prop="name">
            <el-input v-model="ruleForm.zong" size="medium" clearable placeholder="请输入价格">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="底价:" prop="zong">
            <el-input v-model="ruleForm.zong" size="medium" clearable placeholder="请输入价格">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="装修:" prop="value">
            <el-select v-model="year" placeholder="请选择" size="medium" class="width100">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>

      <div class="flex">
        <div>
          <el-form-item label="户型:" prop="value">
            <el-select v-model="year" size="medium" style="width:18%" class="mr5">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <span class="mr5">室</span>
            <el-select v-model="year" size="medium" style="width:18%" class="mr5">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <span class="mr5">厅</span>
            <el-select v-model="year" size="medium" style="width:18%" class="mr5">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <span class="mr5">卫</span>
            <el-select v-model="year" size="medium" style="width:18%" class="mr5">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <span class="mr5">阳</span>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="朝向:" prop="value">
            <el-select v-model="year" placeholder="请选择" size="medium" class="width100">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>

      <div class="flex">
        <div>
          <el-form-item label="建筑面积:" prop="zong">
            <el-input v-model="ruleForm.zong" size="medium" clearable placeholder="请输入">
              <template slot="append">m²</template>
            </el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="使用面积:" prop="zong">
            <el-input v-model="ruleForm.zong" size="medium" clearable placeholder="请输入">
              <template slot="append">m²</template>
            </el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="楼层:" prop="zong">
            <el-input v-model="ruleForm.zong" size="medium" placeholder="" style="width:50%">
              <template slot="prepend">第</template>
              <template slot="append">层</template>
            </el-input>
            <el-input v-model="ruleForm.zong" size="medium" placeholder="" style="width:50%">
              <template slot="prepend">共</template>
              <template slot="append">层</template>
            </el-input>
          </el-form-item>
        </div>
      </div>

      <el-form-item label="卖点:" prop="checkList">
        <el-checkbox-group v-model="ruleForm.checkList">
          <el-checkbox label="复选框 A">学区房</el-checkbox>
          <el-checkbox label="复选框 B">带花园</el-checkbox>
          <el-checkbox label="复选框 C">送露台</el-checkbox>
          <el-checkbox label="禁用">带车库</el-checkbox>
          <el-checkbox label="选中且禁用">江景房</el-checkbox>
          <el-checkbox label="选中且禁用1">山景房</el-checkbox>
          <el-checkbox label="选中且禁用1">带院子</el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <div class="title-desc color409eff" @click="show=!show">
        更多信息
        <i class="el-icon-arrow-down" :class="show?'arrowRotate':'arrow'"></i>
      </div>
      <el-collapse-transition>
        <div v-if="show">
          <div class="flex">
            <div>
              <el-form-item label="来源渠道:" prop="date2">
                <el-select v-model="year" placeholder="请选择" size="medium" class="width100">
                  <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="现状:" prop="date3">
                <el-select v-model="year" placeholder="请选择" size="medium" class="width100">
                  <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="有无电梯:" prop="add" class="width100">
                <div style="height:40px;display:flex;align-items:center">
                  <el-radio v-model="radio1" label="1">无</el-radio>
                  <el-radio v-model="radio1" label="2">有</el-radio>
                  <div v-if="radio1==2">
                    <el-input v-model="ruleForm.zong" size="medium" placeholder="" style="width:40%">
                      <template slot="append">梯</template>
                    </el-input>
                    <el-input v-model="ruleForm.zong" size="medium" placeholder="" style="width:40%">
                      <template slot="append">户</template>
                    </el-input>
                  </div>

                </div>
              </el-form-item>
            </div>
          </div>

          <div class="flex">
            <div>
              <el-form-item label="房屋年限:" prop="value">
                <el-select v-model="year" placeholder="请选择" size="medium" class="width100">
                  <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="建筑结构:" prop="value">
                <el-select v-model="year" placeholder="请选择" size="medium" class="width100">
                  <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="建筑年代:" prop="value">
                <el-input v-model="ruleForm.value" size="medium" clearable placeholder="请输入建筑年代"></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="flex">
            <div>
              <el-form-item label="产权性质:" prop="value">
                <el-select v-model="year" placeholder="请选择" size="medium" class="width100">
                  <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="产权年限:" prop="value">
                <el-select v-model="year" placeholder="请选择" size="medium" class="width100">
                  <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="产证情况:" prop="value">
                <el-select v-model="year" placeholder="请选择" size="medium" class="width100">
                  <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>

          <div class="flex">
            <div>
              <el-form-item label="唯一住房:" prop="value">
                <el-radio v-model="radio" label="1">是</el-radio>
                <el-radio v-model="radio" label="2">否</el-radio>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="付款方式:" prop="value">
                <el-select v-model="year" placeholder="请选择" size="medium" class="width100">
                  <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="有无抵押:" prop="value" class="width100">
                <div style="height:40px;display:flex;align-items:center">
                  <el-radio v-model="radio2" label="1">无</el-radio>
                  <el-radio v-model="radio2" label="2">有</el-radio>
                  <el-input v-model="ruleForm.value" size="medium" placeholder="请输入" v-if="radio2==2">
                    <template slot="prepend">抵押金额</template>
                    <template slot="append">万</template>
                  </el-input>
                </div>
              </el-form-item>
            </div>
          </div>

          <el-form-item label="配套:" prop="checkList">
            <el-checkbox-group v-model="ruleForm.checkList">
              <el-checkbox label="复选框 A">床</el-checkbox>
              <el-checkbox label="复选框 B">电视</el-checkbox>
              <el-checkbox label="复选框 C">空调</el-checkbox>
              <el-checkbox label="禁用">冰箱</el-checkbox>
              <el-checkbox label="选中且禁用">洗衣机</el-checkbox>
              <el-checkbox label="选中且禁用1">沙发</el-checkbox>
              <el-checkbox label="选中且禁用1">餐桌</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <div class="flex">
            <div>
              <el-form-item label="备注:" prop="checkList">
                <el-input type="textarea" :autosize="{ minRows: 6}" placeholder="请输入备注信息" v-model="textarea2" maxlength="300" show-word-limit>
                </el-input>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="隐私备注:" prop="checkList">
                <el-input type="textarea" :autosize="{ minRows: 6}" placeholder="仅录入人可见" v-model="textarea2" maxlength="300" show-word-limit>
                </el-input>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-collapse-transition>
      <div class="flex-c">
        <el-button size="medium">重置</el-button>
        <el-button type="primary" size="medium">确定</el-button>
      </div>

    </el-form>
  </div>
</template>

<script>
export default {
  name: 'EstateProjectAddTwoHouseDwell',
  data () {
    return {
      owner: [],
      ruleForm: {
        name: '',
        region: '',
        value: '',
        state: '',
        dan: '',
        zong: '',
        people: '',
        checkList: [],
        mony: '',
        date: '',
        list: [],
        min: '',
        max: '',
        year: '',
        xiu: [],
        date2: '',
        date3: '',
        add: ''
      },
      radio1: '2',
      radio2: '2',
      rules: {
        name: [
          { required: true, message: '请输入楼盘名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        region: [{ required: true, message: '请选择省市区', trigger: 'blur' }],
        state: [{ required: true, message: '请选择销售状态', trigger: 'blur' }],
        people: [{ required: true, message: '请选择负责人', trigger: 'blur' }]
      },
      options2: [
        { value: '选项1', label: '在售' },
        { value: '选项2', label: '待售' },
        { value: '选项3', label: '售罄' }
      ],
      options3: [{ value: '选项1', label: '陈星' }],
      show: false,
      radio: '2',
      textarea2: '',
      opt: [
        { value: '选项1', label: '栋' },
        { value: '选项2', label: '弄' },
        { value: '选项3', label: '座' },
        { value: '选项4', label: '号' },
        { value: '选项5', label: '号楼' },
        { value: '选项6', label: '胡同' }
      ],
      optval: '选项1',
      zoopt: [
        { value: '选项1', label: '单元' },
        { value: '选项6', label: '栋' },
        { value: '选项2', label: '幢' },
        { value: '选项3', label: '号' },
        { value: '选项5', label: '号楼' }
      ],
      zoval: '选项1'
    }
  },
  methods: {
    addOwner () {
      if (this.owner.length === 4) {
        this.$message({
          message: '最多可添加5个业主信息',
          type: 'warning'
        })
      } else {
        this.owner.push({ name: '' })
      }
    },
    delOwner (index) {
      this.owner.splice(index, 1)
    }
  },
  mounted () { }
}
</script>

<style lang="scss" scoped>
.box {
  border-radius: 6px;
  background-color: #fff;
  padding: 20px;
  .title-info {
    color: #409eff;
    font-size: 16px;
    font-weight: 600;
  }
  .title-desc {
    font-weight: 600;
    font-size: 14px;
    margin: 20px;
  }
  .flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > div {
      width: 500px;
    }
  }
  .mr5 {
    margin-right: 5px;
  }
  .mb20 {
    margin-bottom: 20px;
  }

  .width100 {
    width: 100%;
  }
  .flex-c {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    .el-button--medium {
      padding: 10px 45px;
    }
  }
  .color409eff {
    cursor: pointer;
    color: #409eff;
  }
}
</style>
