<template>
  <div class="box">
    <div class="title-info">新增楼盘</div>
    <div class="title-desc">基本信息</div>
    <el-form :model="form" :rules="rules" ref="form" label-width="120px" size="small">
      <div class="flex">
        <div>
          <el-form-item label="楼盘名称:" prop="Name">
            <el-input v-model="form.Name" size="small" clearable placeholder="请输入楼盘名称"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="省市区:" prop="region">
            <el-cascader v-model="form.region" size="small" clearable class="width100"></el-cascader>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="区域:" prop="value">
            <el-input v-model="form.value" size="small" clearable placeholder="请输入区域"></el-input>
          </el-form-item>
        </div>
      </div>
      <el-form-item label="用途:" prop="checkList">
        <el-checkbox-group v-model="form.checkList">
          <el-checkbox label="复选框 A">住宅</el-checkbox>
          <el-checkbox label="复选框 B">别墅</el-checkbox>
          <el-checkbox label="复选框 C">商办</el-checkbox>
          <el-checkbox label="禁用">商铺</el-checkbox>
          <el-checkbox label="选中且禁用">写字楼</el-checkbox>
          <el-checkbox label="选中且禁用1">公寓</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <div class="flex">
        <div>
          <el-form-item label="销售状态:" prop="state">
            <el-select v-model="form.state" placeholder="请选择" size="small" class="width100">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="单价:" prop="dan">
            <el-input v-model="form.dan" size="small" clearable placeholder="请输入单价">
              <template slot="append">元/m²</template>
            </el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="总价:" prop="zong">
            <el-input v-model="form.zong" size="small" clearable placeholder="请输入总价">
              <template slot="append">万元/套</template>
            </el-input>
          </el-form-item>
        </div>
      </div>

      <div class="flex">
        <div>
          <el-form-item label="负责人:" prop="people">
            <el-select v-model="value3" placeholder="请选择" size="small" class="width100">
              <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="佣金:" prop="mony">
            <el-input v-model="form.mony" size="small" clearable placeholder="请输入佣金">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="期限:" prop="date">
            <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small" class="width100">
            </el-date-picker>
          </el-form-item>
        </div>
      </div>

      <div class="title-desc">小区信息</div>

      <el-form-item label="装修:" prop="checkList">
        <el-checkbox-group v-model="form.list">
          <el-checkbox label="复选框 A">毛坯</el-checkbox>
          <el-checkbox label="复选框 B">简装</el-checkbox>
          <el-checkbox label="复选框 C">普装</el-checkbox>
          <el-checkbox label="禁用">精装</el-checkbox>
          <el-checkbox label="选中且禁用">豪装</el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <div class="flex">
        <div>
          <el-form-item label="面积:" prop="value">
            <div class="width100" style=" display: flex;align-items: center;">
              <el-input v-model="form.min" size="small" placeholder="最小面积" class="mr5" style="width:40%"></el-input>
              <div class="mr5">—</div>
              <el-input v-model="form.max" size="small" placeholder="最大面积" class="mr5" style="width:40%"></el-input>
              <div>m²</div>
            </div>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="产权年限:" prop="value">
            <el-select v-model="year" placeholder="请选择" size="small" class="width100">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>

      <el-form-item label="装修:" prop="checkList">
        <el-checkbox-group v-model="form.xiu">
          <el-checkbox label="复选框 A">绿化率高</el-checkbox>
          <el-checkbox label="复选框 A12">车位充足</el-checkbox>
          <el-checkbox label="复选框 B">现房</el-checkbox>
          <el-checkbox label="复选框 C">学区房</el-checkbox>
          <el-checkbox label="禁用">近地铁 </el-checkbox>
          <el-checkbox label="选中且禁用">花园洋房</el-checkbox>
          <el-checkbox label="禁用">小户型</el-checkbox>
          <el-checkbox label="选中且禁用">低密度</el-checkbox>
          <el-checkbox label="禁用">复式</el-checkbox>
          <el-checkbox label="选中且禁用">品牌地产</el-checkbox>
          <el-checkbox label="禁用">配套纯熟</el-checkbox>
          <el-checkbox label="选中且禁用">优惠楼盘</el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <div class="flex">
        <div>
          <el-form-item label="开盘日期:" prop="date2">
            <el-date-picker v-model="date2" type="date" placeholder="选择日期" size="small" class="width100">
            </el-date-picker>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="最近交房:" prop="date3">
            <el-date-picker v-model="date3" type="date" placeholder="选择日期" size="small" class="width100">
            </el-date-picker>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="详细地址:" prop="add">
            <el-input v-model="form.add" size="small" clearable placeholder="请输入详细地址"></el-input>
          </el-form-item>
        </div>
      </div>

      <div class="flex">
        <div>
          <el-form-item label="开发商:" prop="value">
            <el-input v-model="form.value" size="small" clearable placeholder="请输入开发商"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="总建筑面积:" prop="value">
            <el-input v-model="form.value" size="small" clearable placeholder="请输入总建筑面积">
              <template slot="append">m²</template>
            </el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="容积率:" prop="value">
            <el-input v-model="form.value" size="small" clearable placeholder="请输入容积率"></el-input>
          </el-form-item>
        </div>
      </div>

      <div class="flex">
        <div>
          <el-form-item label="绿化率:" prop="value">
            <el-input v-model="form.value" size="small" clearable placeholder="请输入绿化率"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="小区总户数:" prop="value">
            <el-input v-model="form.value" size="small" clearable placeholder="请输入小区总户数"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="小区栋数:" prop="value">
            <el-input v-model="form.value" size="small" clearable placeholder="请输入小区栋数"></el-input>
          </el-form-item>
        </div>
      </div>

      <div class="flex">
        <div>
          <el-form-item label="物业名称:" prop="value">
            <el-input v-model="form.value" size="small" clearable placeholder="请输入物业名称"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="物业费用:" prop="value">
            <el-input v-model="form.value" size="small" clearable placeholder="请输入物业费用"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="车位数量:" prop="value">
            <el-input v-model="form.value" size="small" clearable placeholder="请输入车位数量"></el-input>
          </el-form-item>
        </div>
      </div>

      <div class="flex-c">
        <el-button size="medium">重置</el-button>
        <el-button type="primary" size="medium">确定</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'EstateProjectAddNewHouse',
  data () {
    return {
      form: {
        name: '',
        region: '',
        value: '',
        state: '',
        dan: '',
        zong: '',
        people: '',
        checkList: [],
        mony: '',
        date: '',
        list: [],
        min: '',
        max: '',
        year: '',
        xiu: [],
        date2: '',
        date3: '',
        add: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入楼盘名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        region: [{ required: true, message: '请选择省市区', trigger: 'blur' }],
        state: [{ required: true, message: '请选择销售状态', trigger: 'blur' }],
        people: [{ required: true, message: '请选择负责人', trigger: 'blur' }]
      },
      options2: [
        { value: '选项1', label: '在售' },
        { value: '选项2', label: '待售' },
        { value: '选项3', label: '售罄' }
      ],
      options3: [{ value: '选项1', label: '陈星' }]
    }
  },

  mounted () { },

  methods: {}
}
</script>

<style lang="scss" scoped>
.box {
  border-radius: 6px;
  background-color: #fff;
  padding: 20px;
  .title-info {
    color: #409eff;
    font-size: 16px;
    font-weight: 600;
  }
  .title-desc {
    font-weight: 600;
    font-size: 14px;
    margin: 20px;
  }
  .flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > div {
      width: 500px;
    }
  }
  .mr5 {
    margin-right: 5px;
  }
  .width100 {
    width: 100%;
  }
  .flex-c {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    .el-button--medium {
      padding: 10px 45px;
    }
  }
}
</style>
