<template>
  <div class="view-box">

    <div class="flex">
      <div class="view-right">
        <el-image style="width: 180px; height: 180px" src="./../../../assets/img/man.png" class="mb20"></el-image>
        <div>编号：2305K00001</div>
      </div>
      <div class="view-left">
        <div class="left-header">
          <div class="header-name">
            <div class="name-text">陈先生</div>
            <el-button type="text" class="mr20" @click="dialogVisibleTel=true">查看联系方式</el-button>
            <el-tag effect="plain" size="small"> A 类 </el-tag>
          </div>
          <el-dropdown placement="bottom" @command="command">
            <el-button type="primary" size="medium">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="dialogVisibleUp">写跟进</el-dropdown-item>
              <el-dropdown-item command="dialogVisibleView">写带看</el-dropdown-item>
              <el-dropdown-item command="dialogVisibleConvert">公转私</el-dropdown-item>
              <el-dropdown-item command="dialogVisibleInvalid">转无效</el-dropdown-item>
              <el-dropdown-item command="edit">修改客源</el-dropdown-item>
              <el-dropdown-item command="delete">删除客源</el-dropdown-item>
              <el-dropdown-item command="dialogVisiblePeople">变更负责人</el-dropdown-item>
              <el-dropdown-item command="dialogVisibleAll">全部记录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="house-type">
          <div>来源渠道：转介绍</div>
          <div>客户获得时间：2023-05-12</div>
          <div>客别：私客</div>
        </div>
        <div class="house-desc ">
          <span>租金：</span>
          <div>
            <div class="mb20 f20">1800-2500</div>
            <div class="c666">住宅</div>
          </div>
          <div>
            <div class="mb20 f20">2室/3室</div>
            <div class="c666">普装/精装</div>
          </div>
          <div>
            <div class="mb20 f20">40-90m²</div>
            <div class="c666">1-6层</div>
          </div>
        </div>
        <div class="house-type">
          <div>负责人：陈大星（广东A店）</div>
          <div>录入人：陈大星（广东A店）</div>
        </div>

      </div>
    </div>

    <div class="info-title">客户需求</div>
    <div class="c666">
      <div class="flex-wrap ">
        <div>
          <span class="title-left">目的：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
        <div>
          <span class="title-left">省市：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
        <div>
          <span class="title-left">区域：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
      </div>
      <div class="flex-wrap ">
        <div>
          <span class="title-left">小区：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
        <div>
          <span class="title-left">商圈：</span>
          <span class="title-desc">xxxxxxxxxxxxxxxx</span>
        </div>
      </div>
      <div class="flex-wrap ">
        <div>
          <span class="title-left">备注：</span>
          <span class="title-desc" style="width:auto">xxxxxxxxxxxxxxxx</span>
        </div>
      </div>
    </div>

    <!-- 联系方式 -->
    <el-dialog title="联系方式" :visible.sync="dialogVisibleTel" width="30%">
      <el-form label-width="120px">
        <el-form-item label="业主：" style="font-weight:900">陈先生- 男-13594665985</el-form-item>
        <el-form-item label="备用联系人：">
          <div>陈先生（男-135846565963）</div>
          <div>方莉莉（女-135997449666）</div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 写跟进 -->
    <el-dialog title="写跟进" :visible.sync="dialogVisibleUp" width="50%">
      <el-form label-width="120px">
        <el-form-item label="跟进内容：">
          <el-input type="textarea" :rows="8" placeholder="请输入内容" maxlength="300" show-word-limit>
          </el-input>
        </el-form-item>
        <el-form-item label="图片上传：">
          <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>最多5张图片，单张大小不超过10M</div>
          <el-dialog :visible.sync="Visible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleUp = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisibleUp = false" size="medium">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 写带看 -->
    <el-dialog title="写带看" :visible.sync="dialogVisibleView" width="50%">
      <el-form label-width="120px" :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item label="带看房源：" prop="name">
          <div class="select-house" @click="dialogVisibleHouse=true"> ES0326549 佛山顺德碧桂园1期</div>
        </el-form-item>
        <el-form-item label="带看人：" prop="name">
          <el-select placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="带看日期：" prop="name">
          <div style="display:flex;align-items:center">
            <el-date-picker type="date" placeholder="选择日期" style="margin-right: 10px;"></el-date-picker>
            <el-radio-group size="medium">
              <el-radio-button label="上午">上午</el-radio-button>
              <el-radio-button label="下午">下午</el-radio-button>
              <el-radio-button label="晚上">晚上</el-radio-button>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="反馈：">
          <el-input type="textarea" :rows="8" placeholder="请输入内容" maxlength="300" show-word-limit>
          </el-input>
        </el-form-item>
        <el-form-item label="图片：">
          <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>最多5张图片，单张大小不超过10M</div>
          <el-dialog :visible.sync="Visible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleView = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisibleView = false" size="medium">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 选择房源 -->
    <el-dialog title="选择房源" :visible.sync="dialogVisibleHouse" width="60%">
      <el-radio-group v-model="radio" size="medium">
        <el-radio-button label="1">新房</el-radio-button>
        <el-radio-button label="2">租房</el-radio-button>
        <el-radio-button label="3">二手房</el-radio-button>
      </el-radio-group>
      <!-- 搜索条 -->
      <div class="search-bar">
        <el-cascader placeholder="区域" size="medium" clearable class="mr10"></el-cascader>
        <el-select v-model="value3" placeholder="户型" multiple collapse-tags size="medium" clearable class="mr10" style="width:180px">
          <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="value6" placeholder="" size="medium" class="mr" style="width:120px">
          <el-option v-for="item in options6" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input v-model="input" placeholder="关键词" size="medium" class="mr10" clearable style="width:200px"></el-input>
        <div>
          <el-button type="primary" size="medium">搜索</el-button>
          <el-button size="medium">重置</el-button>
        </div>
      </div>
      <!-- table表格 -->
      <el-table :data="tableDataNew" border style="width: 100%;" class="mb20" :show-header="false" v-if="radio == 1">
        <el-table-column prop="date" label="预览" width="150" align="center">
          <template>
            <el-image style="width: 120px; height: 90px" :src="url"> </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="楼盘/编号" width="300">
          <template>
            <div class="fw600 f16">佛山顺德碧桂园</div>
            <div>XF000001</div>
            <div>1650万/套</div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="单价/总价">
          <template>
            <div>18280元/m²</div>
            <div>186万元/套</div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="100" align="center">
          <template>
            <el-button type="primary" plain> 确 认 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- table表格 -->
      <el-table :data="tableDataRent" border style="width: 100%;" class="mb20" :show-header="false" v-if="radio == 2">
        <el-table-column prop="date" label="预览" width="150" align="center">
          <template>
            <el-image style="width: 120px; height: 90px" :src="url"> </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="楼盘/编号" width="300">
          <template>
            <div class="fw600 f16">保利新小区·8栋 5单元 808</div>
            <div>陈星 ZF022365</div>
            <div style="color:#ff6633;font-size:16px">1800元/月</div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="单价/总价">
          <template>
            <div>3室2厅2卫1阳</div>
            <div>85.56㎡</div>
            <div>押二付一</div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="100" align="center">
          <template>
            <el-button type="primary" plain> 确 认 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- table表格 -->
      <el-table :data="tableDataTwo" border style="width: 100%;" class="mb20" :show-header="false" v-if="radio == 3">
        <el-table-column prop="date" label="预览" width="150" align="center">
          <template>
            <el-image style="width: 120px; height: 90px" :src="url"> </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="楼盘/编号" width="300">
          <template>
            <div class="fw600 f16">保利新小区·8栋 5单元 808</div>
            <div>陈星 ZF022365</div>
            <div class="tag-box ">
              <el-tag effect="dark" size="small"> 学区房 </el-tag>
              <el-tag effect="dark" size="small"> 近地铁 </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="单价/总价">
          <template>
            <div>3室2厅2卫1阳</div>
            <div>402万</div>
            <div>85.56㎡</div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="100" align="center">
          <template>
            <el-button type="primary" plain> 确 认 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="medium"> 上 一 页 </el-button>
        <el-button type="primary" size="medium"> 下 一 页</el-button>
      </span>
    </el-dialog>

    <!-- 变更负责人 -->
    <el-dialog title="变更负责人" :visible.sync="dialogVisiblePeople" width="30%">
      <el-form label-width="120px">
        <el-form-item label="当前负责人：">
          <span>xxxxxxxx</span>
        </el-form-item>
        <el-form-item label="更改为：">
          <el-select placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisiblePeople = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisiblePeople = false" size="medium">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 全部记录 -->
    <el-dialog title="全部记录" :visible.sync="dialogVisibleAll" width="70%">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="全部" name="first"></el-tab-pane>
        <el-tab-pane label="跟进" name="second"></el-tab-pane>
        <el-tab-pane label="带看" name="third"></el-tab-pane>
        <el-tab-pane label="查看" name="fourth"></el-tab-pane>
        <el-tab-pane label="其他" name="fourth1"></el-tab-pane>
      </el-tabs>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="date" label="操作时间  " width="180">
        </el-table-column>
        <el-table-column prop="name" label="操作人员" width="180">
        </el-table-column>
        <el-table-column prop="address" label="操作记录">
        </el-table-column>
        <el-table-column prop="address" label="图片">
          <template>
            <el-image style="width: 50px; height: 50px" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleAll = false" size="medium">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 转无效 -->
    <el-dialog title="转无效" :visible.sync="dialogVisibleInvalid" width="30%">
      <el-form label-width="120px">
        <el-form-item label="选择原因：">
          <el-select placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleInvalid = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisibleInvalid = false" size="medium">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 公私转 -->
    <el-dialog title="提示" :visible.sync="dialogVisibleConvert" width="30%">
      <div>
        <div style="margin-bottom: 10px;">确定转为私客？</div>
        <div>（转为私客后，只有负责人和他上级们可以查看客户电话）</div>
      </div>
      <!-- <div>
        <div style="margin-bottom: 10px;">确定转为公客？</div>
        <div>（转为公客后，公司里所有人都可以直接看到业主电话）</div>
      </div> -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleConvert = false" size="medium">确 定</el-button>
        <el-button @click="dialogVisibleConvert = false" size="medium">取 消</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'EstateCustomerViewAskRent',
  data () {
    return {
      dialogVisibleTel: false,
      dialogVisibleHouse: false,
      radio: '1',
      options3: [
        { value: '选项1', label: '1室' },
        { value: '选项2', label: '2室' },
        { value: '选项3', label: '3室' },
        { value: '选项4', label: '4室' },
        { value: '选项5', label: '5室' },
        { value: '选项5', label: '5室以上' }
      ],
      value3: '',
      options6: [
        { value: '选项1', label: '小区' },
        { value: '选项2', label: '房源编号' }
      ],
      value6: '选项1',
      input: '',
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      tableDataNew: [
        { date: '2016-05-02', name: '王小虎', address: '上海市普陀区金沙江路 1518 弄' },
        { date: '2016-05-04', name: '王小虎', address: '上海市普陀区金沙江路 1517 弄' },
        { date: '2016-05-01', name: '王小虎', address: '上海市普陀区金沙江路 1519 弄' },
        { date: '2016-05-03', name: '王小虎', address: '上海市普陀区金沙江路 1516 弄' }
      ],
      tableDataRent: [
        { date: '2016-05-02', name: '王小虎', address: '上海市普陀区金沙江路 1518 弄' },
        { date: '2016-05-04', name: '王小虎', address: '上海市普陀区金沙江路 1517 弄' },
        { date: '2016-05-01', name: '王小虎', address: '上海市普陀区金沙江路 1519 弄' },
        { date: '2016-05-03', name: '王小虎', address: '上海市普陀区金沙江路 1516 弄' }
      ],
      tableDataTwo: [
        { date: '2016-05-02', name: '王小虎', address: '上海市普陀区金沙江路 1518 弄' },
        { date: '2016-05-04', name: '王小虎', address: '上海市普陀区金沙江路 1517 弄' },
        { date: '2016-05-01', name: '王小虎', address: '上海市普陀区金沙江路 1519 弄' },
        { date: '2016-05-03', name: '王小虎', address: '上海市普陀区金沙江路 1516 弄' }
      ],
      // 写跟进
      dialogVisibleUp: false,
      dialogImageUrl: '',
      Visible: false,
      // 写带看
      dialogVisibleView: false,
      ruleForm: {
        name: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      },
      options: [{ value: '选项1', label: '黄金糕' }],
      // 变更负责人
      dialogVisiblePeople: false,
      // 全部记录
      activeName: 'first',
      dialogVisibleAll: false,
      tableData: [
        { date: '2016-05-02', name: '王小虎', address: '上海市普陀区金沙江路 1518 弄' },
        { date: '2016-05-04', name: '王小虎', address: '上海市普陀区金沙江路 1517 弄' },
        { date: '2016-05-01', name: '王小虎', address: '上海市普陀区金沙江路 1519 弄' },
        { date: '2016-05-03', name: '王小虎', address: '上海市普陀区金沙江路 1516 弄' }
      ],
      // 转无效
      dialogVisibleInvalid: false,
      // 公私转
      dialogVisibleConvert: false,
      fileList: [],
      innerVisible: false
    }
  },
  methods: {
    command (item) {
      if (item === 'edit') {
        return this.$root.navigate({ name: 'EstateCustomerAddAskRent' })
      }
      if (item === 'delete') {
        return this.$confirm(
          '确定删除客户（ 2305K00001陈先生）?（删除后将不能恢复，建议转为无效）',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
      }
      this[item] = true
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    }
  },
  mounted () { }
}
</script>

<style lang="scss" scoped>
.view-box {
  border-radius: 6px;
  background-color: #fff;
  padding: 20px;
  font-size: 14px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  .view-right {
    text-align: center;
    margin-right: 40px;
  }
  .view-left {
    flex: 1;
    .left-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .header-name {
        display: flex;
        align-items: center;
        .name-text {
          font-size: 18px;
          font-weight: 900;
          margin-right: 20px;
        }
        .mr20 {
          margin-right: 20px;
        }
      }
    }
    .house-type {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      > div {
        font-size: 14px;
        color: #666;
        width: 300px;
        margin-bottom: 15px;
      }
    }
  }
}
.mb20 {
  margin-bottom: 20px;
}
.info-title {
  font-weight: 900;
  font-size: 18px;
  margin: 20px 0;
}
.c666 {
  color: #666666;
}
.house-desc {
  display: flex;
  padding: 15px 0;
  margin-right: 5vw;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 20px;
  > span {
    margin-top: 4px;
    flex-shrink: 0;
    text-align: right;
  }
  > div {
    text-align: center;
    width: 30%;
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .f20 {
    font-size: 20px;
    font-weight: 900;
  }
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  > div {
    display: flex;
    margin-bottom: 30px;
  }
  .title-left {
    flex-shrink: 0;
    width: 100px;
    text-align: right;
  }
  .title-desc {
    width: 300px;
    word-wrap: break-word;
    word-break: break-all;
  }
}
.select-house {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  color: #409eff;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* 搜索条样式 */
.search-bar {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 0;
  > div {
    margin-bottom: 10px;
  }
  .mr {
    margin-right: 5px;
  }
  .f12 {
    font-size: 12px;
  }
  .el-button--medium {
    padding: 9px 20px;
  }
  .mr10 {
    margin-right: 10px;
  }
}
.tag-box {
  margin-top: 10px;
  > span {
    margin-right: 5px;
  }
}
</style>
